import { Container, Paper, makeStyles } from '@material-ui/core';
import React, { Fragment, SFC } from 'react';

import casketBanner from '../static/images/casketBanner.jpg';
import { sandTan } from '../theme';

const useStyles = (src: string) => makeStyles((theme: any) => ({
  headerImage: {
    backgroundColor: sandTan,
    backgroundImage: `url(${src})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    height: '200px',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    position: 'relative',
  },
}));

interface Props {
  src?: string;
}

const Header: SFC<Props> = ({ src = casketBanner }) => {
  const classes = useStyles(src)();

  return (
    <Fragment>
      <Container maxWidth="md">
        <Paper className={classes.headerImage} />
      </Container>
    </Fragment>
  );
};

export default Header;
