import React, { Fragment, FC } from 'react';
import { Typography } from '@material-ui/core';
import DownloadPicker, { AlbumInfo, SideCardStuff } from './DownloadPicker'
import { Download } from '../entities/media';
import albumCover from '../static/images/OTDCover.jpg';
import lyricsCover from '../static/images/lyricsCover.png';
import tabsCover from '../static/images/tabsCover.jpg';

const albumInfo: AlbumInfo = {
  albumCover,
  free: true,
  soundCloudSrc: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/75649596&amp;color=000000&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false',
  subtitle: "Download Satyrasis' sophomore album for free in a format of your choosing.",
  title: '...Of The Dead',
  year: '2015',
};

const downloads: Download[] = [
  {
    color: 'primary',
    format: 'mp3',
    primary: true,
    title: '320kbps MP3',
    type: 'music',
    url: {
      bitly: 'OfTheDead',
      raw: '/media/Satyrasis2015OfTheDead.zip',
    },
  },
  {

    format: 'flac',
    title: '96k/24bit FLAC',
    type: 'music',
    url: {
      bitly: 'OfTheDeadFLAC',
      raw: '/media/SatyrasisOfTheDeadFLAC.zip',
    },
  },
  {
    format: 'wav',
    title: '96k/24bit WAV',
    type: 'music',
    url: {
      bitly: 'OfTheDeadWAV',
      raw: '/media/SatyrasisOfTheDeadWAV.zip',
    },
  },
  {
    format: 'mp3',
    primary: true,
    title: '320kbps MP3',
    type: 'instrumental',
    url: {
      bitly: 'OfTheDeadInstrumental',
      raw: '/media/SatyrasisOfTheDeadInstrumentalMP3.zip',
    },
  },
  {
    format: 'flac',
    title: '96k/24bit FLAC',
    type: 'instrumental',
    url: {
      bitly: 'OfTheDeadInstrumentalFLAC',
      raw: '/media/SatyrasisOfTheDeadInstrumentalFLAC.zip',
    },
  },
  {
    format: 'wav',
    title: '96k/24bit WAV',
    type: 'instrumental',
    url: {
      bitly: 'OfTheDeadInstrumentalWAV',
      raw: '/media/SatyrasisOfTheDeadInstrumentalWAV.zip',
    },
  },
];

const sideCardStuff: SideCardStuff[] = [
  {
    buttonText: 'Download Lyrics',
    image: lyricsCover,
    paragraph: 'A printable lyrics book with custom artwork and careful typesetting.',
    title: 'Lyrics',
    type: 'lyrics',
    url: {
      bitly: 'OfTheDeadLyrics',
      raw: '/media/SatyrasisOfTheDeadLyrics.pdf',
    },
  },
  {
    buttonText: 'Download Tabs',
    image: tabsCover,
    paragraph: 'Guitar Pro and PDF tabs that perfectly track every song on all instruments.',
    title: 'Tabs',
    type: 'tabs',
    url: {
      bitly: 'OfTheDeadTab',
      raw: '/media/SatyrasisOfTheDeadTabs.zip',
    },
  },
];

export const OTDDownloadPicker: FC<{ classes: any }> = ({ classes }) => {

  return (
    <Fragment>

      <Typography className={classes.title} variant="h3">
        {albumInfo.title}
      </Typography>
      <Typography variant="h6">{albumInfo.year}</Typography>
      <DownloadPicker
        albumInfo={albumInfo}
        classes={classes}
        downloads={downloads}
        sideCardStuff={sideCardStuff}
      />
    </Fragment>
  );
}