import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';

import { OTDDownloadPicker } from '../components/OTDDownloadPicker';
import { Footer } from '../components/Footer';
import Header from '../components/Header';
import { Nav } from '../components/Nav';
import { TopLayout } from '../components/TopLayout';
import creationCover from '../static/images/creationCover.jpg';
import { mediaLink } from '../util/redirects';

const styles = {
  card: {
    maxWidth: '400px',
  },
  divider: {
    margin: '40px 0',
  },
  media: {
    height: 0,
    paddingTop: '100%',
  },
  title: {
    margin: '30px 0 15px 0',
  },
};

const CreationDownload = ({ classes }) => (
  <Fragment>
    <Typography className={classes.title} variant="h3">
      Creation of Failure
    </Typography>
    <Typography variant="h6">2008</Typography>
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={creationCover}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography component="h2" gutterBottom variant="h5">
            Creation of Failure
          </Typography>
          <Typography component="p">
            Satyrasis&apos; break-out 2008 album.  Thrashy as all fuck.  Download in full 320kbps CBR fidelity.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          color="primary"
          href={mediaLink('/media/Satyrasis2008CreationOfFailure.zip', 'CreationOfFailure')}
        >
          Download Audio
        </Button>
      </CardActions>
    </Card>
  </Fragment>
);

const Intermediary = withStyles(styles)(({ classes }) => (
  <React.Fragment>
    <Nav />
    <Header />
    <Container maxWidth="lg">
      <OTDDownloadPicker classes={classes} />

      <Divider className={classes.divider} variant="fullWidth" />

      <CreationDownload classes={classes} />

      <Footer />
    </Container>
  </React.Fragment>
));

export default function Default() {
  return (
    <TopLayout>
      <Intermediary />
    </TopLayout>
  );
}
